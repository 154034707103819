import { useState } from 'react'

import { ImageCarousel } from './image-carousel/ImageCoursel'

import thailand from '@/assets/images/thailand@2x.png'
import { MapMarker } from '@/components/react/atoms/MapMarker'
import type { Destination } from '@/types/types'

const locations: Destination[] = [
  {
    text: 'Koh Tao',
    uri: '/thailand/nomads-hostel-koh-tao/',
    top: 70,
    left: 27,
    lineLength: 33,
  },

  {
    text: 'Ao Nang Krabi',
    uri: '/thailand/nomads-backpackers-krabi/',
    top: 79,
    left: 14,
    lineLength: 33,
  },
]

export const ThailandSectionHome = ({ hostels }) => {
  const [currentImageUri, setCurrentImageUri] = useState<string>(null)

  const getImageOnHover = (uri) => {
    setCurrentImageUri(uri)
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 md:gap-8 items-center mt-32 xl:mt-80 md:mt-20 container">
      <div className="order-2 md:order-1 col-span-1 md:col-span-5 relative hidden md:block ">
        <ImageCarousel
          scrollToUri={currentImageUri}
          borderColor="border-pink"
          items={hostels.map((item) => ({
            id: item.uri,
            title: 'Thailand Hostels',
            subtitle: item.title,
            imageUrl: item.featuredImage.node.sourceUrl,
            srcSet: item.featuredImage.node.srcSet,
            altText: item.featuredImage.node.altText,
            uri: item.uri,
          }))}
        />
      </div>
      <div className="grid order-1 md:order-2 md:col-start-8 md:col-end-13">
        <div className="relative w-fit max-w-[100%] md:max-w-[60%] xl:max-w-[80%] 3xl:max-w-[90%] ml-2 md:ml-0  mr-3 ">
          <h3 className=" text-pink text-4xl md:text-3xl lg:text-5xl 3xl:text-[52px] font-black text-right mr-3">
            Thailand
          </h3>
          <img
            src={thailand.src}
            width={thailand.width}
            height={thailand.height}
            alt="Thailand"
            loading="lazy"
            className="object-cover w-[100%]"
          />
          {locations.map((location) => (
            <MapMarker
              text={location.text}
              uri={location.uri}
              top={location.top}
              left={location.left}
              lineLength={location.lineLength}
              getImageOnHover={getImageOnHover}
              key={location.uri}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
